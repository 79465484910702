<template>
  <div class="v-stack h-stretch gap-3">
    <div class="card light border h-stretch">
      <div class="content h-stretch">
        <form class="gap-3 h-stretch">
          <a>Info</a>
          <label class="text-left required">Name</label>
          <input type="text" v-model="drive.name" />
          <label class="text-left">Product Name</label>
          <input type="text" v-model="drive.productName" />
          <label class="text-left">Type</label>
          <select v-model="drive.external">
            <option :value="true">External</option>
            <option :value="false">Internal</option>
          </select>
          <label class="text-left">Storage Type</label>
          <select v-model="drive.type">
            <option v-for="type in driveTypes" :key="type">{{ type }}</option>
          </select>
          <label class="text-left">Form factor</label>
          <select v-model="drive.formFactor">
            <option>2.5"</option>
            <option>3.5"</option>
            <option>PCIe</option>
            <option>M.2</option>
          </select>
          <label class="text-left">Size (TB)</label>
          <input type="number" v-model="drive.size" />
          <label class="text-left">Purchase Date</label>
          <InputDatePicker v-model="drive.dateOfPurchase"></InputDatePicker>
          <label class="text-left">Store</label>
          <input type="text" v-model="drive.storeDescription" />
          <a>Speed</a>
          <div class="h-stack h-stretch gap-3">
            <div class="v-stack h-stretch gap-3">
              <label class="text-left">Read (MB/s)</label>
              <input type="number" v-model="drive.speedRead" />
            </div>
            <div class="v-stack h-stretch gap-3">
              <label class="text-left">Write (MB/s)</label>
              <input type="number" v-model="drive.speedWrite" />
            </div>
            <div class="v-stack h-stretch gap-3">
              <label class="text-left">RPM</label>
              <select v-model="drive.speedRPM">
                <option>0</option>
                <option>5400</option>
                <option>7200</option>
                <option>10000</option>
              </select>
            </div>
          </div>
          <a>Other</a>
          <label class="text-left">Description</label>
          <textarea v-model="drive.description"></textarea>
          <div class="h-stack h-end">
            <button class="submit" @click.prevent="submit()">
              {{ buttonLabel }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import constants from "@/constants.js";
import InputDatePicker from "@/components/calendar/InputDatePicker.vue";

export default {
  props: ["id"],
  data() {
    return {
      drive: {},
      driveTypes: constants.driveTypes,
    };
  },
  components: {
    InputDatePicker,
  },
  methods: {
    ...mapActions(["getDrive", "addDrive", "updateDrive"]),
    submit() {
      if (this.id) {
        this.updateDrive(this.drive)
          .then(() => {
            this.$router.go(-1);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.addDrive(this.drive)
          .then(() => {
            this.$router.go(-1);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  computed: {
    buttonLabel() {
      if (this.id) {
        return "Submit";
      }
      return "Add";
    },
  },
  mounted() {
    if (this.id) {
      this.getDrive(this.id)
        .then((drive) => {
          this.drive = drive;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
};
</script>
